import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { UserNotification } from "../../graphcms/vo/graphCMS";

const UserNotifications: React.FC = props => {
  const userNotifications: UserNotification[] = useSelector(
    (state: State) => state.graphcms.userNotifications,
  );

  if (userNotifications.length === 0) {
    return null;
  }

  return (
    <>
      {userNotifications.map((userNotification, i) => {
        return (
          <p
            key={`userNotification${userNotification.id}`}
            className="item-alert"
            style={{ color: "red" }}
          >
            {userNotification.text}
            <span className="item-alert__icon icon-font icon-font__alert" aria-hidden="true"></span>
          </p>
        );
      })}
    </>
  );
};

export default UserNotifications;
