import {
  initCategoriesTree,
  initPfs,
  initProductGroups,
  initProducts,
  loadUserNotifications,
} from "graphcms/services/init";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PARAMS } from "../../router/constants/Params";
import { useEffect } from "react";

function initGraphCMS() {
  initCategoriesTree().then();
  initProductGroups().then();
  initProducts().then();
  loadUserNotifications().then();
}

export function useInitGraphCMS() {
  const params = useParams();
  const { i18n } = useTranslation();

  // use language from i18n and not from params. graphcms will use i18n.language as param
  const { language } = i18n;
  const country = params[PARAMS.COUNTRY];

  // on country change or on language change
  useEffect(() => {
    // prevents calling this effect on initial render when i18n.language is undefined
    // https://3.basecamp.com/3101128/buckets/19695186/todos/3594241983
    if (!language) {
      return;
    }

    if (!country) {
      return;
    }

    initGraphCMS();
  }, [language, country]);

  // init once
  useEffect(() => {
    initPfs().then();
  }, []);
}
