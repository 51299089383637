import React from "react";
import RouterWrapper from "./router/components/RouterWrapper";
import AppErrorBoundary from "./common/components/AppErrorBoundary";
import { useTranslation } from "react-i18next";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NewVersionBanner from "./layout/components/NewVersionBanner";
import CookieBanner from "./layout/components/CookieBanner";

const App: React.FC = () => {
  const { ready, i18n } = useTranslation();

  if (!ready) return null;
  if (!i18n.language) return null;

  return (
    <React.StrictMode>
      <HelmetProvider>
        <AppErrorBoundary>
          <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
          </Helmet>
          <ToastContainer
            position="top-center"
            autoClose={false}
            transition={Slide}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            pauseOnFocusLoss={true}
            draggable={true}
            pauseOnHover={true}
            theme="colored"
          />
          <RouterWrapper />
          <CookieBanner />
          <NewVersionBanner />
        </AppErrorBoundary>
      </HelmetProvider>
    </React.StrictMode>
  );
};

export default App;
