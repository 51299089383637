import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import GraphCMS, { ProductGroupsIndex, ProductsIndex } from "../../state/vo/GraphCMS";
import { Category, Pf, Product, ProductGroup, UserNotification } from "../vo/graphCMS";

const name = "graphcms";
const initialState = store.graphcms;

const graphcmsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCategoriesTree(state: GraphCMS, action: PayloadAction<Category[]>) {
      state.categoriesTree = action.payload;
    },
    setProductGroups(state: GraphCMS, action: PayloadAction<ProductGroup[]>) {
      state.productGroups = action.payload;
    },
    setProductGroupsIndex(state: GraphCMS, action: PayloadAction<ProductGroup[]>) {
      const index: ProductGroupsIndex = {};

      action.payload.forEach(productGroup => {
        index[productGroup?.id ?? ""] = productGroup;
      });

      state.productGroupsIndex = index;
    },
    setProductGroupsHasInit(state: GraphCMS, action: PayloadAction<boolean>) {
      state.productGroupsHasInit = action.payload;
    },
    setProducts(state: GraphCMS, action: PayloadAction<Product[]>) {
      state.products = action.payload;
    },
    setProductsIndex(state: GraphCMS, action: PayloadAction<Product[]>) {
      const index: ProductsIndex = {};

      action.payload.forEach(product => {
        index[product?.sku ?? ""] = product;
      });

      state.productsIndex = index;
    },
    setProductHasInit(state: GraphCMS, action: PayloadAction<boolean>) {
      state.productsHasInit = action.payload;
    },
    setPfs(state: GraphCMS, action: PayloadAction<Pf[]>) {
      state.pfs = action.payload;
    },
    setUserNotifications(state: GraphCMS, action: PayloadAction<UserNotification[]>) {
      state.userNotifications = action.payload;
    },
  },
});

export const setCategoriesTree = graphcmsSlice.actions.setCategoriesTree;
export const setProductGroups = graphcmsSlice.actions.setProductGroups;
export const setProductGroupsIndex = graphcmsSlice.actions.setProductGroupsIndex;
export const setProductGroupsHasInit = graphcmsSlice.actions.setProductGroupsHasInit;
export const setProducts = graphcmsSlice.actions.setProducts;
export const setProductsIndex = graphcmsSlice.actions.setProductsIndex;
export const setProductHasInit = graphcmsSlice.actions.setProductHasInit;
export const setPfs = graphcmsSlice.actions.setPfs;
export const setUserNotifications = graphcmsSlice.actions.setUserNotifications;

export default graphcmsSlice.reducer;
