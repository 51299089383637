import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getPathnameCountrySwitch, getPathnameLangSwitch } from "../../router/scripts/path";
import i18next from "i18next";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import {
  setCartOpen,
  setCookies,
  setLiveSearchOpen,
  setOverlayOpen,
  setSapMaintenanceEnabled,
  setShowConsignmentStockInfo,
} from "../slices/layoutSlice";
import { StorageKeys } from "../../common/constants/StorageKeys";
import { setCountry } from "../../router/slices/urlParamsSlice";
import { initCookies } from "../scripts/cookies";
import { OVERLAY } from "../constants/Overlay";
import { getLangCountry } from "../../router/scripts/link";
import LayerIo from "../../common/services/LayerIo";

export async function langSwitch(newLang: string) {
  // update current pathname to new lang
  const pathname = getPathnameLangSwitch(newLang);
  // set new lang in path first.
  // this makes the upgrading of urlParams right in the first update cycle
  boundReplaceLocObj({ pathname });
  // change for dictionary
  await i18next.changeLanguage(newLang);
  localStorage.setItem(StorageKeys.BASE_HREF, `${window.location.origin}${getLangCountry()}`);
}

export async function countrySwitch(newCountry: string) {
  // update current pathname to new country
  const pathname = getPathnameCountrySwitch(newCountry);
  dispatch(setCountry(newCountry));
  boundReplaceLocObj({ pathname });
  localStorage.setItem(StorageKeys.BASE_HREF, `${window.location.origin}${getLangCountry()}`);
}

export async function setCookiesAction(cookies: string) {
  dispatch(setCookies(cookies));
  localStorage.setItem(StorageKeys.COOKIES, cookies);
  if (cookies === "yes") {
    initCookies().then();
  }
}

export async function setSapMaintenance() {
  const enabled = await LayerIo.requestJsonRpc<any, any>("sap-maintenance", "enabled", {});
  dispatch(setSapMaintenanceEnabled(enabled.data));
}

export async function setCartOpenAction(cartOpen: boolean) {
  dispatch(setCartOpen(cartOpen));
}

export async function setLiveSearchOpenAction(liveSearchOpen: boolean) {
  dispatch(setLiveSearchOpen(liveSearchOpen));
}

export function setOverlayOpenAction(overlayOpen: OVERLAY | null) {
  dispatch(setOverlayOpen(overlayOpen));
}

export function setShowConsignmentStockInfoAction(showConsignmentStockInfo: boolean) {
  dispatch(setShowConsignmentStockInfo(showConsignmentStockInfo));
}
