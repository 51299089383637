import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { SyncType } from "../../common/constants/SyncType";

const NewVersionBanner: React.FC = () => {
  const [showNewVersionBanner, setShowNewVersionBanner] = useState<boolean>(false);

  function setEventListeners() {
    // Set the Event Listener for Reloading / Syncing the Tabs and Content
    window.addEventListener("storage", e => {
      // prevent doing anything when the oldValue equals the newValue solved
      // an infinity loop problem with IE11
      if (e.oldValue !== e.newValue) {
        if (e.key === SyncType.RELOAD_OTHER_TABS) {
          window.location.reload();
        }
      }
    });

    window.addEventListener(SyncType.NEW_CONTENT_AVAILABLE, () => {
      setShowNewVersionBanner(true);
    });
  }

  useEffect(() => {
    setEventListeners();
  }, []);

  function loadNewContent() {
    setShowNewVersionBanner(false);
    // trigger sync event for all open tabs via reloading all others tabs
    window.localStorage.setItem(SyncType.RELOAD_OTHER_TABS, String(Math.random()));

    // reload current tab to finish new content loading
    window.location.reload();
  }

  if (!showNewVersionBanner) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <div className="cookie-banner__container">
        <p>
          <strong>{i18next.t("NEW VERSION AVAILABLE")}</strong>
        </p>

        <div className="btn-wrap">
          <button
            type="submit"
            onClick={() => setShowNewVersionBanner(false)}
            className="btn transparent"
          >
            {`${i18next.t("CLOSE")[0].toUpperCase() + i18next.t("CLOSE").substring(1)}`}
          </button>

          <button type="submit" onClick={loadNewContent} className="btn">
            {`${i18next.t("LOAD NOW")}`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewVersionBanner;
